<template>
    <div>
      <div class="banner">
        {{ $t("home.banner") }}
      </div>
      <div class="home-header text-title-3 text-bold">
        Account Settings
      </div>
      <div class="settings-container">
        <Pill :clickable="false">
          <template #alt>
            <div class="pill-container">
              <div class="text-body-2 text-bold">
                Account information
              </div>
              <div class="input-item">
                <div class="input-label">
                  Email
                </div>
                <div class="input-fields">
                  <div class="input-text text-body-4">
                    {{ user.email }}
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </template>
        </Pill>
        <Pill v-if="scopes.includes('query:pro_api')" :clickable="false">
          <template #alt>
            <div class="pill-container">
              <div class="text-body-2 text-bold">
                API keys
              </div>
              <div>
                You only need the items below if you are programmatically calling APIs.
              </div>
              <div>
                Please store your tokens in a secure place!
              </div>
              <div class="input-item">
                <div class="input-label">
                  JWT Token
                </div>
                <div class="input-fields vertical">
                  <HiddenText
                    :text="jwtToken"
                    :canSee="canSeeJwt"
                    :toggleSee="toggleJwt"
                  />
                  <div class="input-action-container">
                    <div
                      @click="copyURL(jwtToken)"
                      class="input-action input-label"
                    >
                      Copy
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div>
                WARNING: if you click to renew your refresh token it will invalidate the any old tokens!
              </div>
              <div class="input-item">
                <div class="input-label">
                  Refresh token
                </div>
                <div class="input-fields vertical">
                  <HiddenText
                    :text="refreshToken"
                    :canSee="canSeeRefresh"
                    :toggleSee="toggleRefresh"
                  />
                  <div class="input-action-container">
                    <div
                      @click="copyURL(refreshToken)"
                      class="input-action input-label"
                    >
                      Copy
                    </div>
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    &nbsp;&nbsp;
                    <div
                      @click="setRefreshToken()"
                      class="input-action input-label"
                    >
                      NEW REFRESH
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Pill>
        <a
            href="https://docs.openmeasures.io/partners/apis"
            target="_blank"
            v-if="scopes.includes('query:pro_api')"
        >
          <Pill
            title="API Documentation"
            class="hover"
            body="https://docs.openmeasures.io/partners/apis"
          />
        </a>
        <Pill
          title="Pro API Endpoint"
          body="https://pro.api.openmeasures.io"
          v-if="scopes.includes('query:pro_api')"
          tool="api"
          doCopy
          class="hover"
        >
        </Pill>
        <Pill
          title="Crawl Requests Endpoint"
          body="https://crawl-requests-api-gateway-agrquh75.uc.gateway.dev/crawl_request"
          :tool="CRAWLREQUESTS"
          class="hover"
          doCopy
          v-if="scopes.includes('access:crawl_requests')"
        />
        <Pill
          title="Network Graph API Endpoint"
          body="https://network-graph-api-gateway-agrquh75.uc.gateway.dev/"
          v-if="scopes.includes('query:graphql')"
          :tool="NETWORK"
          doCopy
          class="hover"
        />
      </div>
    </div>
  </template>
<script>
import Pill from '@/components/Pill'
import HiddenText from '@/components/input/HiddenText'
import { CRAWLREQUESTS, NETWORK } from '../constants/tools'

export default {
  components: {
    Pill,
    HiddenText,
  },
  data: function () {
    return {
      user: this.$auth0.user,
      scopes: JSON.parse(
        window.localStorage.getItem(
          Object.keys(window.localStorage).filter(key => key.startsWith('@@'))[0]
        )
      ).body.oauthTokenScope,
      canSeeJwt: false,
      canSeeRefresh: false,
      jwtToken: window.localStorage.getItem('access-token') || JSON.parse(
        window.localStorage.getItem(
          Object.keys(window.localStorage).filter(key => key.startsWith('@@'))[0]
        )
      ).body.access_token,
      refreshToken: JSON.parse(
        window.localStorage.getItem(
          Object.keys(window.localStorage).filter(key => key.startsWith('@@'))[0]
        )
      ).body.refresh_token,
      NETWORK,
      CRAWLREQUESTS,
    }
  },
  methods: {
    async copyURL (mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
        alert('Copied! Please store your JWT tokens in a secure place!')
      } finally {
        this.canSeeJwt = false
        this.canSeeRefresh = false
      }
    },
    async toggleJwt () {
      this.canSeeJwt = !this.canSeeJwt
    },
    toggleRefresh () {
      this.canSeeRefresh = !this.canSeeRefresh
    },
    async setToken () {
      const token = await this.$auth0.getAccessTokenSilently()
      window.localStorage.setItem('access-token', token)
      this.jwtToken = token
      alert('Received new token! Any old JWT tokens are now invalid.')
    },
    async setRefreshToken () {
      window.localStorage.removeItem(
        Object.keys(window.localStorage).filter(key => key.startsWith('@@'))[0]
      )
      await this.$auth0.loginWithRedirect(
        {
          audience: 'http://google_api',
          scope: 'offline_access openid email profile query:pro_api query:graphql access:crawl_requests access:media access:kibana access:privateui query:media_api',
          cacheMode: 'off',
          redirect_uri: window.location.href
        }
      )
      alert('Received new token! Any old JWT tokens are now invalid.')
    }
  },
}
</script>

<style scoped lang="scss">
$page-padding: 40px;

.banner {
  width: 100%;
  background-color: #8a22a5;
  color: #f8f5f3;
  text-align: center;
  padding: 10px 0;
  font-family: var(--primary-font);
  font-size: 15px;
}

.home-header {
  padding: $page-padding var(--spacing-side-padding);
  margin-bottom: 24px;
  width: calc(100vw - 2 * var(--spacing-side-padding));
}

.pill-container {
  flex: 1;
}

.settings-container {
  .input-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .input-item {
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 8px;
    .input-fields {
      display: flex;
      margin-bottom: -8px;
      &.vertical {
        flex-direction: column;
      }
      div {
        margin-top: 4px;
      }
      .input-action-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .input-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .input-footer-text {
      width: 100%;
      justify-content: flex-end;
      margin-right: 24px;
      margin-bottom: 0;
    }
  }
}

.input-action {
  max-width: 160px;
  margin-bottom: 10px;
  padding: 0;
  text-align: right;
  color: var(--color-primary);

  &:hover {
    cursor: pointer;
  }
}

.hover {
  &:hover, &.selected { border-left: 5px solid var(--color-purple);}
}
</style>
